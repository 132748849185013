<template>
  <div class="row animated fadeIn">
    <div class="col-lg-12 d-flex flex-column">
      <div class="float-right ml-auto">
        <WButton info
          v-if="!isDisable"
          @click="updateOrCreateAccessServer()"
        >
          {{ $t('portalAccessServers.createAccessServerButtonCaption') }}
        </WButton>
      </div>
<!--      <Table-pagination-v2-->
<!--        :on-submit="getWithQuery.bind(this)"-->
<!--        v-model="filters"-->
<!--        :show-filters="['search', 'sort', 'limit']"-->
<!--        :options="[-->
<!--          { value: 'name', label: $t('portalAccessServers.name') },-->
<!--          { value: 'description', label: $t('portalAccessServers.description') },-->
<!--          { value: 'enable', label: $t('portalAccessServers.enable') },-->
<!--          { value: 'type', label: $t('portalAccessServers.type') },-->
<!--          { value: 'coa_port', label: $t('portalAccessServers.coaPort') },-->
<!--          { value: 'external_ip', label: $t('portalAccessServers.externalIP') },-->
<!--          { value: 'location', label: $t('portalAccessServers.location') },-->
<!--          { value: 'login', label: $t('portalAccessServers.login') },-->
<!--          { value: 'mac_auth', label: $t('portalAccessServers.macAuth') },-->
<!--          { value: 'nas_id', label: $t('portalAccessServers.nasId') },-->
<!--          { value: 'nas_ip', label: $t('portalAccessServers.nasIP') }-->
<!--        ]"-->
<!--      />-->
      <Table-pagination-v3
        v-if="showTablePaginationV3Component"
        small-width-location-selector
        small-height-for-custom-multiselect-and-select-components
        :on-submit="getWithQuery.bind(this)"
        v-model="filters"
        :show-filters="['search', 'sort', 'limit', 'location']"
        :options="[
          { value: 'name', label: $t('portalAccessServers.name') },
          { value: 'description', label: $t('portalAccessServers.description') },
          { value: 'enable', label: $t('portalAccessServers.enable') },
          { value: 'type', label: $t('portalAccessServers.type') },
          { value: 'coa_port', label: $t('portalAccessServers.coaPort') },
          { value: 'external_ip', label: $t('portalAccessServers.externalIP') },
          { value: 'location', label: $t('portalAccessServers.location') },
          { value: 'login', label: $t('portalAccessServers.login') },
          { value: 'mac_auth', label: $t('portalAccessServers.macAuth') },
          { value: 'nas_id', label: $t('portalAccessServers.nasId') },
          { value: 'nas_ip', label: $t('portalAccessServers.nasIP') }
        ]"
      />

    </div>

      <div class="col-lg-4 mt-0">
        <div class="filters__multiple">
          <div class="input-group input-group-sm filters__wlan flex-wrap mr-1">
            <WButton secondary outline sm
              v-if="isFiltersActive"
              @click.stop.prevent="resetAllFilters"
            >
              <i class="fa fa-close"></i>
              {{ $t('general.resetAllFilters') }}
            </WButton>
          </div>
        </div>
      </div>

    <div class="col-lg-12">
      <div class="card">
        <div v-if="isShowingSpinner">
          <Loader-spinner />
        </div>
        <div class="card-header d-flex align-items-center justify-content-between">
          <div>
            <i class="mr-0"></i>
            {{ $t('portalAccessServers.tableCaption') }}
            <span class="text-muted ml-3" v-if="accessServersListFromAPI.length">
              {{ this.accessServersListFromAPI.length }} {{ this.$t('portal.paginationOf') }}
              {{ this.totalResult }}
            </span>
          </div>
          <div class="d-flex align-items-center">
            <download-extract-v2
              newXlsAutoColumnsWidth
              v-if="!isShowingSpinner && accessServersListFromAPI && accessServersListFromAPI.length > 0"
              class="ml-h"
              forceEnableA2PdfPageSize
              :data="formatToPDF(accessServersListFromAPI)"
              :data-for-excel="formatToExcel(accessServersListFromAPI)"
              :excel-file-name="getFileNameForDownloadFiles($t('downloadFilesNames.portalAccessServers'))"
              :filename="getFileNameForDownloadFiles($t('downloadFilesNames.portalAccessServers'))"
              :pdfTableColumnsWidths="pdfTableColumnsWidths"
              :downloads-formats-for-show="{
                oldCsv: true,
                newCsv: false,
                oldXls: false,
                newXls: true,
                pdf: true
              }"
            />
          </div>
        </div>
        <div class="card-block card-block__table table-responsive">
          <table class="table table-hover">
            <thead>
              <tr class="">
                <th scope="col" class="text-nowrap align-middle text-center"></th>
                <th scope="col" class="align-middle text-center">{{ $t('portalAccessServers.name') }}</th>
                <th scope="col" class="text-nowrap align-middle text-center">{{ $t('portalAccessServers.type') }}</th>
                <th scope="col" class="text-nowrap align-middle text-center">
                  {{ $t('portalAccessServers.description') }}
                </th>
                <th scope="col" class="align-middle text-center">{{ $t('portalAccessServers.nasId') }}</th>
                <th scope="col" class="text-nowrap align-middle text-center">{{ $t('portalAccessServers.nasIP') }}</th>
                <th scope="col" class="align-middle text-left">
                  <div>{{ $t('portalAccessServers.login') }},</div>
                  <div>{{ $t('portalAccessServers.password') }}</div>
                </th>
                <th scope="col" class="align-middle text-left">
                  <div>{{ $t('portalAccessServers.coaPort') }},</div>
                  <div>{{ $t('portalAccessServers.coaSecret') }},</div>
                  <div>{{ $t('portalAccessServers.coaVsaForTable') }}</div>
                </th>
                <th scope="col" class="align-middle text-center">{{ $t('portalAccessServers.externalIP') }}</th>
                <th scope="col" class="align-middle text-center">{{ $t('portalAccessServers.macAuth') }}</th>
                <th scope="col" class="text-nowrap align-middle text-center">
                  {{ $t('portalAccessServers.location') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                class=""
                v-for="accessServer in accessServersListFromAPI"
                :key="accessServer.id"
                @click="updateOrCreateAccessServer(accessServer)"
              >
                <td class="align-middle text-center">
                  <small
                    class="badge badge-light badge-success"
                    v-if="accessServer.enable && accessServer.enable === true"
                  >
                    {{ $t('portalAccessServers.enableForTable') }}
                  </small>
                  <small class="badge badge-light badge-default" v-else>
                    {{ $t('portalAccessServers.disableForTable') }}
                  </small>
                </td>

                <td class="align-middle text-center">
                  <span v-if="accessServer.name">
                    {{ accessServer.name }}
                  </span>
                  <EmptyCellValue v-else />
                </td>
                <td class="align-middle text-center">
                  <span v-if="accessServer.type">
                    {{ getDisplayNameForAccessServerType(accessServer.type) }}
                  </span>
                  <EmptyCellValue v-else />
                </td>
                <td
                  class="align-middle text-center max-width-in-portal-access-servers-table"
                  v-tooltip.right="{ content: tooltipForDescriptionColumnInTable(accessServer) }"
                >
                  <span v-if="accessServer.description">
                    {{ accessServer.description }}
                  </span>
                  <EmptyCellValue v-else />
                </td>
                <td
                  class="align-middle text-center max-width-in-portal-access-servers-table"
                  v-tooltip.right="{ content: tooltipForNasIdColumnInTable(accessServer) }"
                >
                  <span v-if="accessServer.nas_id">
                    {{ accessServer.nas_id }}
                  </span>
                  <EmptyCellValue v-else />
                </td>
                <td class="align-middle text-center">
                  <span v-if="accessServer.nas_ip">
                    {{ accessServer.nas_ip }}
                  </span>
                  <EmptyCellValue v-else />
                </td>
                <td
                  class="align-middle text-left max-width-in-portal-access-servers-table"
                  v-tooltip.right="{ content: tooltipForLoginPasswordColumnInTable(accessServer) }"
                >
                  <div class="max-width-in-portal-access-servers-table">
                    <span>{{ $t('portalAccessServers.login') }}:</span>
                    <span v-if="accessServer.login">
                      {{ accessServer.login }}
                    </span>
                    <span v-else class="text-muted">{{ $t('portalAccessServers.noForTable') }}</span>
                  </div>
                  <div class="max-width-in-portal-access-servers-table">
                    <span>{{ $t('portalAccessServers.password') }}:</span>
                    <span v-if="accessServer.password">
                      {{ accessServer.password }}
                    </span>
                    <span v-else class="text-muted">{{ $t('portalAccessServers.noForTable') }}</span>
                  </div>
                </td>
                <td
                  class="align-middle text-left"
                  v-tooltip.right="{ content: tooltipForCOAColumnInTable(accessServer) }"
                >
                  <div class="max-width-in-portal-access-servers-table">
                    <span>{{ $t('portalAccessServers.coaPort') }}:</span>
                    <span v-if="accessServer.coa_port">
                      {{ accessServer.coa_port }}
                    </span>
                    <span v-else class="text-muted">{{ $t('portalAccessServers.noForTable') }}</span>
                  </div>
                  <div class="max-width-in-portal-access-servers-table">
                    <span>{{ $t('portalAccessServers.coaSecret') }}:</span>
                    <span v-if="accessServer.coa_secret">
                      {{ accessServer.coa_secret }}
                    </span>
                    <span v-else class="text-muted">{{ $t('portalAccessServers.noForTable') }}</span>
                  </div>
                  <div class="max-width-in-portal-access-servers-table">
                    <span>{{ $t('portalAccessServers.coaVsaForTable') }}:</span>
                    <span v-if="accessServer.coa_vsa">
                      {{ accessServer.coa_vsa }}
                    </span>
                    <span v-else class="text-muted">{{ $t('portalAccessServers.noForTable') }}</span>
                  </div>
                </td>
                <td class="align-middle text-center">
                  <span v-if="accessServer.external_ip">
                    {{ accessServer.external_ip }}
                  </span>
                  <EmptyCellValue v-else />
                </td>
                <td class="align-middle text-center">
                  <small
                    class="badge badge-light badge-success"
                    v-if="accessServer.mac_auth && accessServer.mac_auth === true"
                  >
                    {{ $t('portalAccessServers.enableForTable') }}
                  </small>
                  <small class="badge badge-light badge-default" v-else>
                    {{ $t('portalAccessServers.disableForTable') }}
                  </small>
                </td>
                <td
                  class="align-middle text-center max-width-in-portal-access-servers-table"
                  v-tooltip.right="{ content: tooltipForLocationColumnInTable(accessServer) }"
                >
                  <span v-if="accessServer.location">
                    {{ accessServer.location }}
                  </span>
                  <EmptyCellValue v-else />
                </td>
              </tr>
              <tr v-if="!accessServersListFromAPI.length">
                <td colspan="20" class="no-data-row">
                  <span class="text-muted pt-1">{{ $t('general.noDataToDisplay') }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- </div>
          </div> -->
        </div>
        <div
          v-if="
            this.canLoadMore &&
            this.accessServersListFromAPI.length &&
            this.totalResult - this.accessServersListFromAPI.length
          "
          class="card-block text-center p-0"
        >
          <WButton fullWidth
            @click="loadMore"
          >
            {{ this.$t('portal.paginationLoad') }}
            <span v-if="this.filters.limit < this.totalResult - this.accessServersListFromAPI.length">
              {{ this.filters.limit }}
            </span>
            <span v-else>{{ this.totalResult - this.accessServersListFromAPI.length }}</span>
            {{ this.$t('portal.paginationAdsOutOf') }}
            {{ this.totalResult - this.accessServersListFromAPI.length }}
          </WButton>
        </div>
      </div>
    </div>
    <modal
      :backdrop="false"
      :title="
        isNew
          ? this.$t('portalAccessServers.modalCreateAccessServerHeader')
          : this.$t('portalAccessServers.modalUpdateAccessServerHeader')
      "
      class="modal-info"
      closeBtn
      @cancel="handleCancelButtonInModal"
      effect="fade/zoom"
      v-model="isAccessServerModalOpen"
    >
      <h4 slot="modal-title" class="modal-title">
        {{
          isNew
            ? this.$t('portalAccessServers.modalCreateAccessServerHeader')
            : this.$t('portalAccessServers.modalUpdateAccessServerHeader')
        }}
      </h4>

      <!--      <div class="form-group">-->
      <!--        <pre>{{updatingAccessServer}}</pre>-->
      <!--      </div>-->
      <!--        enable-->
      <div class="form-group">
        <label for="enable">{{ $t('portalAccessServers.enable') }}</label>
        <br />
        <Switch-component
          v-model="updatingAccessServer.enable"
          :label="''"
          :disabled="isDisable"
          :class="{ 'disable-turned-on-switcher-in-access-servers': isDisable }"
          name="enable"
          id="enable"
        />
      </div>
      <!--      name-->
      <div class="form-group">
        <label for="name">{{ $t('portalAccessServers.name') }}</label>

        <input
          :data-vv-as="$t('portalAccessServers.name')"
          type="text"
          name="name"
          id="name"
          :placeholder="$t('portalAccessServers.modalNameFieldPlacheolder')"
          class="form-control"
          v-validate="'required|max:50'"
          :class="{ input: true, 'is-danger': errors.has('name') }"
          v-model="updatingAccessServer.name"
          :disabled="isDisable"
          data-vv-validate-on="input"
        />

        <span v-show="errors.has('name')" class="help is-danger">{{ errors.first('name') }}</span>
      </div>

      <!--     type-->
      <div class="form-group">
        <label for="type">{{ $t('portalAccessServers.type') }}</label>
        <select :disabled="isDisable" v-model="updatingAccessServer.type" id="type" name="type" class="form-control">
          <option v-for="typeItem in accessServersTypesList" :key="typeItem.typeForAPI" :value="typeItem.typeForAPI">
            {{ typeItem.typeForDisplay }}
          </option>
        </select>
      </div>

      <!--      description-->
      <div class="form-group">
        <label for="name">{{ $t('portalAccessServers.description') }}</label>

        <input
          :data-vv-as="$t('portalAccessServers.description')"
          type="text"
          name="description"
          id="description"
          :placeholder="$t('portalAccessServers.modalDescriptionFieldPlaceholder')"
          class="form-control"
          v-validate="'max:100'"
          :class="{ input: true, 'is-danger': errors.has('description') }"
          v-model="updatingAccessServer.description"
          :disabled="isDisable"
          data-vv-validate-on="input"
        />

        <span v-show="errors.has('description')" class="help is-danger">{{ errors.first('description') }}</span>
      </div>

      <!--      nas_id -->
      <div class="form-group">
        <label for="name">{{ $t('portalAccessServers.nasId') }}</label>

        <input
          :data-vv-as="$t('portalAccessServers.nasId')"
          type="text"
          name="nas_id"
          id="nas_id"
          :placeholder="$t('portalAccessServers.modalNasIdFieldPlaceholder')"
          class="form-control"
          v-validate="'max:100'"
          :class="{ input: true, 'is-danger': errors.has('nas_id') }"
          v-model="updatingAccessServer.nas_id"
          :disabled="isDisable"
          data-vv-validate-on="input"
        />

        <span v-show="errors.has('nas_id')" class="help is-danger">{{ errors.first('nas_id') }}</span>
      </div>

      <!--      nas_ip -->
      <div class="form-group">
        <label for="name">{{ $t('portalAccessServers.nasIP') }}</label>

        <input
          :data-vv-as="$t('portalAccessServers.nasIP')"
          type="text"
          name="nas_ip"
          id="nas_ip"
          :placeholder="$t('portalAccessServers.modalNasIpFieldPlaceholder')"
          class="form-control"
          v-validate="'portalAccessServersNasIP'"
          :class="{ input: true, 'is-danger': errors.has('nas_ip') }"
          v-model="updatingAccessServer.nas_ip"
          :disabled="isDisable"
          @input="handleModalNasIPFieldInput"
          data-vv-validate-on="none"
        />

        <span v-show="errors.has('nas_ip')" class="help is-danger">{{ errors.first('nas_ip') }}</span>
      </div>

      <!--      login -->
      <div class="form-group">
        <label for="name">{{ $t('portalAccessServers.login') }}</label>

        <input
          :data-vv-as="$t('portalAccessServers.login')"
          type="text"
          name="login"
          id="login"
          :placeholder="$t('portalAccessServers.modalLoginFieldPlaceholder')"
          class="form-control"
          :class="{ input: true, 'is-danger': errors.has('login') }"
          v-model="updatingAccessServer.login"
          :disabled="isDisable"
          v-validate="'max:50'"
          data-vv-validate-on="input"
        />

        <span v-show="errors.has('login')" class="help is-danger">{{ errors.first('login') }}</span>
      </div>

      <!--      password -->
      <div class="form-group">
        <label for="name">{{ $t('portalAccessServers.password') }}</label>

        <input
          :data-vv-as="$t('portalAccessServers.password')"
          type="text"
          name="password"
          id="password"
          :placeholder="$t('portalAccessServers.modalPasswordFieldPlaceholder')"
          class="form-control"
          :class="{ input: true, 'is-danger': errors.has('password') }"
          v-model="updatingAccessServer.password"
          :disabled="isDisable"
          v-validate="'max:50'"
          data-vv-validate-on="input"
        />

        <span v-show="errors.has('password')" class="help is-danger">{{ errors.first('password') }}</span>
      </div>

      <!--      coa_port -->
      <div class="form-group">
        <label for="name">{{ $t('portalAccessServers.coaPort') }}</label>

        <input
          :data-vv-as="$t('portalAccessServers.coaPort')"
          type="text"
          name="coa_port"
          id="coa_port"
          :placeholder="$t('portalAccessServers.modalCoaPortFieldPlaceholder')"
          class="form-control"
          :class="{ input: true, 'is-danger': errors.has('coa_port') }"
          v-model.number="updatingAccessServer.coa_port"
          :disabled="isDisable"
          v-validate="'numeric'"
          data-vv-validate-on="input"
        />

        <span v-show="errors.has('coa_port')" class="help is-danger">{{ errors.first('coa_port') }}</span>
      </div>
      <!--      coa_secret -->
      <div class="form-group">
        <label for="name">{{ $t('portalAccessServers.coaSecret') }}</label>

        <input
          :data-vv-as="$t('portalAccessServers.coaSecret')"
          type="text"
          name="coa_secret"
          id="coa_secret"
          :placeholder="$t('portalAccessServers.modalCoaSecretFieldPlaceholder')"
          class="form-control"
          :class="{ input: true, 'is-danger': errors.has('coa_secret') }"
          v-model="updatingAccessServer.coa_secret"
          :disabled="isDisable"
          v-validate="'max:50'"
          data-vv-validate-on="input"
        />

        <span v-show="errors.has('coa_secret')" class="help is-danger">{{ errors.first('coa_secret') }}</span>
      </div>

      <!--      coa_vsa -->
      <div class="form-group">
        <label for="name">{{ $t('portalAccessServers.coaVsa') }}</label>

        <textarea
          :data-vv-as="$t('portalAccessServers.coaVsa')"
          type="text"
          name="coa_vsa"
          id="coa_vsa"
          :placeholder="$t('portalAccessServers.modalCoaVsaFieldPlaceholder')"
          class="form-control"
          :class="{ input: true, 'is-danger': errors.has('coa_vsa') }"
          v-model="updatingAccessServer.coa_vsa"
          :disabled="isDisable"
          v-validate="'max:300'"
          rows="3"
          data-vv-validate-on="input"
        />

        <span v-show="errors.has('coa_vsa')" class="help is-danger">{{ errors.first('coa_vsa') }}</span>
      </div>

      <!--      external_ip -->
      <div class="form-group">
        <label for="name">{{ $t('portalAccessServers.externalIP') }}</label>

        <input
          :data-vv-as="$t('portalAccessServers.externalIP')"
          type="text"
          name="external_ip"
          id="external_ip"
          :placeholder="$t('portalAccessServers.modalExternalIPFieldPlaceholder')"
          class="form-control"
          v-validate="'portalAccessServersExternalIP'"
          :class="{ input: true, 'is-danger': errors.has('external_ip') }"
          v-model="updatingAccessServer.external_ip"
          :disabled="isDisable"
          @input="handleModalExternalIPFieldInput"
          data-vv-validate-on="none"
        />

        <span v-show="errors.has('external_ip')" class="help is-danger">{{ errors.first('external_ip') }}</span>
      </div>

      <!--        mac_auth-->
      <div class="form-group">
        <label for="mac_auth">{{ $t('portalAccessServers.macAuth') }}</label>
        <br />
        <Switch-component
          v-model="updatingAccessServer.mac_auth"
          :label="''"
          :disabled="isDisable"
          :class="{ 'disable-turned-on-switcher-in-access-servers': isDisable }"
          id="mac_auth"
          name="mac_auth"
        />
      </div>

      <!--      pre_auth_vsa -->
      <div class="form-group">
        <label for="name">{{ $t('portalAccessServers.preAuthVsa') }}</label>

        <textarea
          :data-vv-as="$t('portalAccessServers.preAuthVsa')"
          type="text"
          name="pre_auth_vsa"
          id="pre_auth_vsa"
          :placeholder="$t('portalAccessServers.modalPreAuthVsaFieldPlaceholder')"
          class="form-control"
          :class="{ input: true, 'is-danger': errors.has('pre_auth_vsa') }"
          v-model="updatingAccessServer.pre_auth_vsa"
          :disabled="isDisable"
          v-validate="'max:300'"
          rows="3"
          data-vv-validate-on="input"
        />

        <span v-show="errors.has('pre_auth_vsa')" class="help is-danger">{{ errors.first('pre_auth_vsa') }}</span>
      </div>

      <!--      post_auth_vsa -->
      <div class="form-group">
        <label for="name">{{ $t('portalAccessServers.postAuthVsa') }}</label>

        <textarea
          :data-vv-as="$t('portalAccessServers.postAuthVsa')"
          type="text"
          name="post_auth_vsa"
          id="post_auth_vsa"
          :placeholder="$t('portalAccessServers.modalPostAuthVsaFieldPlaceholder')"
          class="form-control"
          :class="{ input: true, 'is-danger': errors.has('post_auth_vsa') }"
          v-model="updatingAccessServer.post_auth_vsa"
          :disabled="isDisable"
          v-validate="'max:300'"
          rows="3"
          data-vv-validate-on="input"
        />

        <span v-show="errors.has('post_auth_vsa')" class="help is-danger">{{ errors.first('post_auth_vsa') }}</span>
      </div>

      <!--      location -->
      <div class="form-group">
        <label for="name">{{ $t('portalAccessServers.location') }}</label>

        <input
          :data-vv-as="$t('portalAccessServers.location')"
          type="text"
          name="location"
          id="location"
          :placeholder="$t('portalAccessServers.modalLocationFieldPlaceholder')"
          class="form-control"
          :class="{ input: true, 'is-danger': errors.has('location') }"
          v-model="updatingAccessServer.location"
          :disabled="isDisable"
          v-validate="'max:50'"
          data-vv-validate-on="input"
        />

        <span v-show="errors.has('location')" class="help is-danger">{{ errors.first('location') }}</span>
      </div>
      <div class="form-group d-flex justify-content-center">
        <div class="validatorErrors" v-if="errors.any()">
          {{ $t('portalAccessServers.validatorErrors') }}
        </div>
      </div>

      <div slot="modal-footer" class="modal-footer justify-content-between">
        <div class="justify-content-between">
          <WButton success outline
            customClass="mr-1"
            v-if="!isDisable"
            @click="handleSaveButtonInModal"
            :disabled="isDisableButtonsInModalWindow"
          >
            <span :class="{ invisible: isDisableButtonsInModalWindow }">
              {{ isNew ? $t('general.create') : $t('general.save') }}
            </span>
            <span v-if="isDisableButtonsInModalWindow" class="loader loader--mini" />
          </WButton>
        </div>
        <div class="justify-content-between">
          <WButton danger outline
            customClass="mr-2"
            v-if="!isNew && !isDisable"
            @click="handleDeleteButtonInModal"
            :disabled="isDisableButtonsInModalWindow"
          >
            <span :class="{ invisible: isDisableButtonsInModalWindow }">{{ $t('general.delete') }}</span>
            <span v-if="isDisableButtonsInModalWindow" class="loader loader--mini" />
          </WButton>
          <WButton secondary outline
            @click="handleCancelButtonInModal"
            :disabled="isDisableButtonsInModalWindow"
          >
            <span :class="{ invisible: isDisableButtonsInModalWindow }">{{ $t('general.close') }}</span>
            <span v-if="isDisableButtonsInModalWindow" class="loader loader--mini" />
          </WButton>
        </div>
      </div>
    </modal>
    <Delete-dialog
      :is-open="isDeleteOpen"
      :handle-close="handleDeleteDialogClose.bind(this)"
      :handle-delete="deleteAccessServer.bind(this)"
      :target-text="updatingAccessServer.name"
    />
  </div>
</template>

<script>
import portalAccessServersService from '../../services/portalAccessServersService';
import Info from '../../components/Universal/info-icon.vue';
import Modal from '../../components/Modal.vue';
import EmptyCellValue from '../../components/empty-cell-value.vue';
import SwitchComponent from '../../components/Universal/Switch-component.vue';
import DeleteDialog from '../../components/delete-dialog.vue';
import TablePaginationV3 from '../../components/table-pagination-v3.vue';
import helpers from '../../helpers';

export default {
  name: 'PortalAccessServers',
  components: {
    Info,
    Modal,
    EmptyCellValue,
    SwitchComponent,
    DeleteDialog,
    TablePaginationV3
  },
  inject: ['$validator'],
  data() {
    return {
      showTablePaginationV3Component: true,
      pdfTableColumnsWidths: [
        '3%',
        '10%',
        '6%',
        '6%',
        '6%',
        '6%',
        '6%',
        '6%',
        '6%',
        '6%',
        '6%',
        '6%',
        '6%',
        '6%',
        '6%',
        '6%'
      ],
      totalResult: false,
      canLoadMore: true,
      offset: 0,
      isDeleteOpen: false,
      accessServersListFromAPI: [],
      filters: {
        limit: 30,
        offset: 0,
        sort_by: 'name',
        sort_order: 1 /* 1 for ascending, -1 for descending */,
        search: '',
        location: {
          data: {},
          with_childs: false,
        }

      },
      isAccessServerModalOpen: false,
      isNew: false,
      updatingAccessServer: {},
      newAccessServer: {
        // bson: false,
        enable: false,
        coa_port: 3799,
        coa_secret: '',
        coa_vsa: '',
        description: '',
        external_ip: '',
        location: '',
        login: '',
        mac_auth: false,
        name: '',
        nas_id: '',
        nas_ip: '',
        password: '',
        pre_auth_vsa: '',
        post_auth_vsa: '',
        type: 'Wimark'
      },
      accessServersTypesList: [
        {
          typeForAPI: 'Cisco ISG',
          typeForDisplay: 'Cisco ISG'
        },
        {
          typeForAPI: 'Cisco WLC',
          typeForDisplay: 'Cisco WLC'
        },
        {
          typeForAPI: 'CoovaChilli',
          typeForDisplay: 'Coova Chilli'
        },
        {
          typeForAPI: 'EcoBRAS',
          typeForDisplay: 'Eco BRAS'
        },
        {
          typeForAPI: 'Wimark',
          typeForDisplay: 'Wimark'
        }
      ]
    };
  },
  // created() {
  //   portalAccessServersService.getAccessServers(this, { query: true });
  // },
  created() {

    let inHeaderLocationSelectedApplying = true;
    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }
      if (locationForFiltering === 'All locations') {
        this.filters.location.data = {};
        this.filters.location.with_childs = true;
      } else {
        this.filters.location.data = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
        this.filters.location.with_childs = withChildsForFiltering
      }
    }


    // portalAccessServersService.getAccessServers(this, { query: true });
    portalAccessServersService.getAccessServers(this, { query: true, useLocationFilter: true });
  },
  computed: {
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    isFiltersActive() {
      return this.filters.limit !== 30 || this.filters.sort_by !== 'name' ||
        this.filters.sort_order !== 1 || this.filters.search !== '' ||
        (typeof this.filters.location === 'object' && Object.prototype.hasOwnProperty.call(this.filters.location, 'data') &&
        typeof this.filters.location.data  === 'object' && Object.keys(this.filters.location.data).length !== 0 )
    },
    isShowingSpinner() {
      return (
        this.isportalAccessServersGetRequestExecuting ||
        this.isportalAccessServersUpdateRequestExecuting ||
        this.isportalAccessServersCreateRequestExecuting ||
        this.isportalAccessServersDeleteRequestExecuting
      );
    },
    isportalAccessServersGetRequestExecuting() {
      return this.$store.state.portalAccessServersGetRequestExecuting;
    },
    isportalAccessServersUpdateRequestExecuting() {
      return this.$store.state.portalAccessServersUpdateRequestExecuting;
    },
    isportalAccessServersCreateRequestExecuting() {
      return this.$store.state.portalAccessServersCreateRequestExecuting;
    },
    isportalAccessServersDeleteRequestExecuting() {
      return this.$store.state.portalAccessServersDeleteRequestExecuting;
    },
    isDisableButtonsInModalWindow() {
      return (
        this.isportalAccessServersUpdateRequestExecuting ||
        this.isportalAccessServersCreateRequestExecuting ||
        this.isportalAccessServersDeleteRequestExecuting
      );
      // return true
    }
  },
  methods: {
    getFileNameForDownloadFiles(pageName) {
      return helpers.getFileNameForDownloadFiles(pageName)
    },
    // resetAllFilters(){
    //   this.filters = {
    //     limit: 30,
    //     offset: 0,
    //     sort_by: 'name',
    //     sort_order: 1 /* 1 for ascending, -1 for descending */,
    //     search: '',
    //     location: {
    //       data: {},
    //       with_childs: false,
    //     }
    //   },
    //   this.redrawTablePaginationV3Component();
    //   this.getWithQuery();
    // },
    resetAllFilters(){
      this.filters = {
        limit: 30,
        offset: 0,
        sort_by: 'name',
        sort_order: 1 /* 1 for ascending, -1 for descending */,
        search: '',
        // location: {
        //   data: {},
        //   with_childs: false,
        // }
      }
      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = {};
        this.filters.location.data = {};
        this.filters.location.with_childs = false;
      } else {
        this.filters.location = {};
        this.filters.location.data = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
        this.filters.location.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)
      }


      this.redrawTablePaginationV3Component();
      this.getWithQuery();
    },
    redrawTablePaginationV3Component(){
      this.showTablePaginationV3Component = false;
      this.$nextTick(()=>{
        this.showTablePaginationV3Component = true;
      })
    },
    formatToExcel(accessServersListFromAPI) {
      const result = {};
      if (accessServersListFromAPI.length === 0) {
        return result;
      }
      result[this.$t('portalAccessServers.tableCaption')] = [];
      const portalAccessServersisticToExcel = result[this.$t('portalAccessServers.tableCaption')];
      portalAccessServersisticToExcel.push([
        this.$t('portalAccessServers.enable'),
        this.$t('portalAccessServers.name'),
        this.$t('portalAccessServers.type'),
        this.$t('portalAccessServers.description'),
        this.$t('portalAccessServers.nasId'),
        this.$t('portalAccessServers.nasIP'),
        this.$t('portalAccessServers.login'),
        this.$t('portalAccessServers.password'),
        // this.$t('portalAccessServers.time_stamp'),
        this.$t('portalAccessServers.coaPort'),
        this.$t('portalAccessServers.coaSecret'),
        this.$t('portalAccessServers.coaVsa'),
        this.$t('portalAccessServers.externalIP'),
        this.$t('portalAccessServers.macAuth'),
        this.$t('portalAccessServers.postAuthVsa'),
        this.$t('portalAccessServers.preAuthVsa'),
        this.$t('portalAccessServers.location')
      ]);

      accessServersListFromAPI.forEach((server) => {
        let enable = '';
        let name = '';
        let type = '';
        let description = '';
        let nas_id = '';
        let nas_ip = '';
        let login = '';
        let password = '';
        let coa_port = '';
        let coa_secret = '';
        let coa_vsa = '';
        let external_ip = '';
        let mac_auth = '';
        let post_auth_vsa = '';
        let pre_auth_vsa = '';
        let location = '';

        if (server.hasOwnProperty('enable')) {
          // enable = server.enable;
          if (server.enable === true) {
            enable = this.$t('portalAccessServers.enableForTable');
          } else {
            enable = this.$t('portalAccessServers.disableForTable');
          }
        }
        if (server.hasOwnProperty('name')) {
          name = server.name;
        }
        if (server.hasOwnProperty('type')) {
          type = this.getDisplayNameForAccessServerType(server.type);
        }
        if (server.hasOwnProperty('description')) {
          description = server.description;
        }
        if (server.hasOwnProperty('nas_id')) {
          nas_id = server.nas_id;
        }
        if (server.hasOwnProperty('nas_ip')) {
          nas_ip = server.nas_ip;
        }
        if (server.hasOwnProperty('login')) {
          login = server.login;
        }
        if (server.hasOwnProperty('password')) {
          password = server.password;
        }
        if (server.hasOwnProperty('coa_port')) {
          coa_port = server.coa_port;
        }
        if (server.hasOwnProperty('coa_secret')) {
          coa_secret = server.coa_secret;
        }
        if (server.hasOwnProperty('coa_vsa')) {
          coa_vsa = server.coa_vsa;
        }
        if (server.hasOwnProperty('external_ip')) {
          external_ip = server.external_ip;
        }
        if (server.hasOwnProperty('mac_auth')) {
          // mac_auth = server.mac_auth;
          if (server.mac_auth === true) {
            mac_auth = this.$t('portalAccessServers.enableForTable');
          } else {
            mac_auth = this.$t('portalAccessServers.disableForTable');
          }
        }
        if (server.hasOwnProperty('post_auth_vsa')) {
          post_auth_vsa = server.post_auth_vsa;
        }
        if (server.hasOwnProperty('pre_auth_vsa')) {
          pre_auth_vsa = server.pre_auth_vsa;
        }
        if (server.hasOwnProperty('location')) {
          location = server.location;
        }

        // console.log(cpe);
        portalAccessServersisticToExcel.push([
          enable,
          name,
          type,
          description,
          nas_id,
          nas_ip,
          login,
          password,
          coa_port,
          coa_secret,
          coa_vsa,
          external_ip,
          mac_auth,
          post_auth_vsa,
          pre_auth_vsa,
          location
        ]);
      });

      return result;
    },
    formatToPDF(accessServersListFromAPI) {
      if (!accessServersListFromAPI) {
        return [];
      }

      let csvAccessServersListFromAPI = JSON.parse(JSON.stringify(accessServersListFromAPI));

      csvAccessServersListFromAPI = csvAccessServersListFromAPI.map((server) => {
        if (server.hasOwnProperty('enable')) {
          server.enable = server.enable;
          if (server.enable === true) {
            server.enable = this.$t('portalAccessServers.enableForTable');
          } else {
            server.enable = this.$t('portalAccessServers.disableForTable');
          }
        } else {
          server.enable = '';
        }
        if (server.hasOwnProperty('name')) {
          server.name = server.name;
        } else {
          server.name = '';
        }
        if (server.hasOwnProperty('type')) {
          server.type = this.getDisplayNameForAccessServerType(server.type);
        } else {
          server.type = '';
        }
        if (server.hasOwnProperty('description')) {
          server.description = server.description || '';
        } else {
          server.description = '';
        }
        if (server.hasOwnProperty('nas_id')) {
          server.nas_id = server.nas_id || '';
        } else {
          server.nas_id = '';
        }
        if (server.hasOwnProperty('nas_ip')) {
          server.nas_ip = server.nas_ip || '';
        } else {
          server.nas_ip = '';
        }

        if (server.hasOwnProperty('login')) {
          server.login = server.login || '';
        } else {
          server.login = '';
        }
        if (server.hasOwnProperty('password')) {
          server.password = server.password || '';
        } else {
          server.password = '';
        }
        if (server.hasOwnProperty('coa_port')) {
          server.coa_port = server.coa_port || '';
        } else {
          server.coa_port = '';
        }
        if (server.hasOwnProperty('coa_secret')) {
          server.coa_secret = server.coa_secret || '';
        } else {
          server.coa_secret = '';
        }
        if (server.hasOwnProperty('coa_vsa')) {
          server.coa_vsa = server.coa_vsa || '';
        } else {
          server.coa_vsa = '';
        }
        if (server.hasOwnProperty('external_ip')) {
          server.external_ip = server.external_ip || '';
        } else {
          server.external_ip = '';
        }
        if (server.hasOwnProperty('mac_auth')) {
          server.mac_auth = server.mac_auth || '';
          if (server.mac_auth === true) {
            server.mac_auth = this.$t('portalAccessServers.enableForTable');
          } else {
            server.mac_auth = this.$t('portalAccessServers.disableForTable');
          }
        } else {
          server.mac_auth = '';
        }
        if (server.hasOwnProperty('post_auth_vsa')) {
          server.post_auth_vsa = server.post_auth_vsa || '';
        } else {
          server.post_auth_vsa = '';
        }
        if (server.hasOwnProperty('pre_auth_vsa')) {
          server.pre_auth_vsa = server.pre_auth_vsa || '';
        } else {
          server.pre_auth_vsa = '';
        }
        if (server.hasOwnProperty('location')) {
          server.location = server.location || '';
        } else {
          server.location = '';
        }

        // дальше выстраиваем в определнном порядке, без этого в pdf данные перемешиваются между колонками
        // const portalAccessServersisticKeys = ['id', 'sessions', 'offPeriod', 'name', 'model', 'base_location', 'connected'];
        const AccessServersKeys = [
          'enable',
          'name',
          'type',
          'description',
          'nas_id',
          'nas_ip',
          'login',
          'password',
          'coa_port',
          'coa_secret',
          'coa_vsa',
          'external_ip',
          'mac_auth',
          'post_auth_vsa',
          'pre_auth_vsa',
          'location'
        ];
        const AccessServersWithSortedKeys = {};
        const AccessServersTranslateKeys = {
          enable: this.$t('portalAccessServers.enable'),
          name: this.$t('portalAccessServers.name'),
          type: this.$t('portalAccessServers.type'),
          description: this.$t('portalAccessServers.description'),
          nas_id: this.$t('portalAccessServers.nasId'),
          nas_ip: this.$t('portalAccessServers.nasIP'),
          login: this.$t('portalAccessServers.login'),
          password: this.$t('portalAccessServers.password'),
          coa_port: this.$t('portalAccessServers.coaPort'),
          coa_secret: this.$t('portalAccessServers.coaSecret'),
          coa_vsa: this.$t('portalAccessServers.coaVsa'),
          external_ip: this.$t('portalAccessServers.externalIP'),
          mac_auth: this.$t('portalAccessServers.macAuth'),
          post_auth_vsa: this.$t('portalAccessServers.postAuthVsa'),
          pre_auth_vsa: this.$t('portalAccessServers.preAuthVsa'),
          location: this.$t('portalAccessServers.location')
        };
        for (const key of AccessServersKeys) {
          AccessServersWithSortedKeys[AccessServersTranslateKeys[key]] = server[key];
        }
        return AccessServersWithSortedKeys;
      });
      return csvAccessServersListFromAPI;
    },
    pushAccessServersList(list) {
      // console.log(list)
      if (this.accessServersListFromAPI.length === 0) {
        this.accessServersListFromAPI = JSON.parse(JSON.stringify(list));
      } else {
        this.accessServersListFromAPI = this.accessServersListFromAPI.concat(JSON.parse(JSON.stringify(list)));
      }
      // console.log(this.accessServersListFromAPI)
    },
    loadMore() {
      this.offset++;
      this.filters.offset = this.offset * this.filters.limit;
      // console.log(this.filters.offset);
      // portalAccessServersService.getAccessServers(this, { query: true });
      portalAccessServersService.getAccessServers(this, { query: true, useLocationFilter: true });
    },
    getDisplayNameForAccessServerType(typeForAPI) {
      let result = typeForAPI;
      for (const typeItem of this.accessServersTypesList) {
        if (typeItem.typeForAPI === typeForAPI) {
          result = typeItem.typeForDisplay;
          break;
        }
      }
      return result;
    },
    tooltipForDescriptionColumnInTable(accessServer) {
      return accessServer.description;
    },
    tooltipForNasIdColumnInTable(accessServer) {
      return accessServer.nas_id;
    },
    tooltipForLoginPasswordColumnInTable(accessServer) {
      const login = accessServer.login ? accessServer.login : this.$t('portalAccessServers.noForTable');
      const password = accessServer.password ? accessServer.password : this.$t('portalAccessServers.noForTable');
      let result = '';
      result += `${this.$t('portalAccessServers.login')}: ${login}`;
      result += '<br>';
      result += `${this.$t('portalAccessServers.password')}: ${password}`;
      return result;
    },
    tooltipForCOAColumnInTable(accessServer) {
      const coa_port = accessServer.coa_port ? accessServer.coa_port : this.$t('portalAccessServers.noForTable');
      const coa_secret = accessServer.coa_secret ? accessServer.coa_secret : this.$t('portalAccessServers.noForTable');
      const coa_vsa = accessServer.coa_vsa ? accessServer.coa_vsa : this.$t('portalAccessServers.noForTable');
      let result = '';
      result += `${this.$t('portalAccessServers.coaPort')}: ${coa_port}`;
      result += '<br>';
      result += `${this.$t('portalAccessServers.coaSecret')}: ${coa_secret}`;
      result += '<br>';
      result += `${this.$t('portalAccessServers.coaVsa')}: ${coa_vsa}`;
      return result;
    },
    tooltipForLocationColumnInTable(accessServer) {
      return accessServer.location;
    },
    tooltipForPREAndPOSTAuthVSAColumnInTable(accessServer) {
      const pre_auth_vsa = accessServer.pre_auth_vsa ?
        accessServer.pre_auth_vsa :
        this.$t('portalAccessServers.noForTable');
      const post_auth_vsa = accessServer.post_auth_vsa ?
        accessServer.post_auth_vsa :
        this.$t('portalAccessServers.noForTable');
      let result = '';
      result += `${this.$t('portalAccessServers.preAuthVsa')}: ${pre_auth_vsa}`;
      result += '<br>';
      result += `${this.$t('portalAccessServers.postAuthVsa')}: ${post_auth_vsa}`;
      return result;
    },
    updateOrCreateAccessServer(accessServerForUpdating = undefined) {
      let isAccessServerCreating = false;
      if (accessServerForUpdating === undefined) {
        isAccessServerCreating = true;
      }
      if (isAccessServerCreating) {
        this.isNew = true;
        this.updatingAccessServer = JSON.parse(JSON.stringify(this.newAccessServer));
      } else {
        this.isNew = false;
        this.updatingAccessServer = JSON.parse(JSON.stringify(accessServerForUpdating));
      }
      this.openAccessServerModal();
    },

    openAccessServerModal() {
      this.isAccessServerModalOpen = true;
      this.$nextTick(() => {
        // прокручиваем к верху модалки (например если ранее закрыли просматривая низ модалки)
        // ищем поле для ввода имени, просто потому что оно в верхней части модалки
        const fieldInTopOfModalWindow = document.getElementById('name');
        if (fieldInTopOfModalWindow) {
          fieldInTopOfModalWindow.scrollIntoView({ block: 'center', behavior: 'auto' });
        }
      });
    },
    closeAccessServerModal() {
      this.isAccessServerModalOpen = false;
    },
    handleSaveButtonInModal() {
      // console.log('click save')
      if (this.isNew) {
        this.createAccessServer(this.updatingAccessServer);
      } else {
        this.updateAccessServer(this.updatingAccessServer);
      }
    },
    handleCancelButtonInModal() {
      this.clearUpdatingAccessServer();
      this.closeAccessServerModal();
      this.clearValidatorErrors();
    },
    closeModalAfterUpdateOrCreateAccessServer() {
      this.handleCancelButtonInModal();
    },
    closeModalAfterDeleteAccessServer() {
      this.closeDeleteWindow();
      this.handleCancelButtonInModal();
    },
    handleDeleteButtonInModal() {
      this.openDeleteWindow();
    },
    clearUpdatingAccessServer() {
      this.updatingAccessServer = {};
    },
    clearValidatorErrors() {
      this.errors.clear();
    },
    updateAccessServer(accessServer) {
      this.setDefaultCoaPortIfNotEntered(accessServer);
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          portalAccessServersService.updateAccessServer(this, accessServer);
        } else {
          this.$nextTick(() => {
            this.scrollAndFocusToFirstFieldWithError();
          });
        }
      });
    },
    createAccessServer(accessServer) {
      this.setDefaultCoaPortIfNotEntered(accessServer);
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          portalAccessServersService.createAccessServer(this, accessServer);
        } else {
          this.$nextTick(() => {
            this.scrollAndFocusToFirstFieldWithError();
          });
        }
      });
    },
    handleModalNasIPFieldInput() {
      // очищаем ошибку поля nas ip, если она есть, каждый раз когда начанаем вводить nas ip
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has('nas_ip')) {
          this.errors.remove('nas_ip');
        }
      }
    },
    handleModalExternalIPFieldInput() {
      // очищаем ошибку поля external_ip, если она есть, каждый раз когда начанаем вводить external_ip
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has('external_ip')) {
          this.errors.remove('external_ip');
        }
      }
    },
    portalAccessServersUpdateListAfterUpdatingOnBackend(updatedAccessServer) {
      // если ничего не передали просто обновляем список серверов. Хотя такого быть не должно
      if (!updatedAccessServer || updatedAccessServer === undefined) {
        this.clearFiltersAndQuery();
        // portalAccessServersService.getAccessServers(this, { query: true });
        portalAccessServersService.getAccessServers(this, { query: true, useLocationFilter: true });
        return;
      }
      // вызывается при успешном обновлении Access Server после PUT запроса на бэк
      // ищет нужный сервер в списке серверов (по соответсвию ID'шников) и обновяет его
      // чтобы не запрашивать все сервера заново c бэка
      // console.log(updatedSession);
      let foundAccessServerById = false;
      if (this.accessServersListFromAPI && Array.isArray(this.accessServersListFromAPI)) {
        if (updatedAccessServer.hasOwnProperty('id') && updatedAccessServer.id !== '') {
          for (const accessServerIndex in this.accessServersListFromAPI) {
            if (this.accessServersListFromAPI[accessServerIndex].id === updatedAccessServer.id) {
              this.$set(
                this.accessServersListFromAPI,
                accessServerIndex,
                JSON.parse(JSON.stringify(updatedAccessServer))
              );
              foundAccessServerById = true;
              break;
            }
          }
          if (!foundAccessServerById) {
            // если не нашли что обновлять по id серверо доступа (а такого быть не должно)
            // тога просто обнволяем список всех серверов доступа, чтобы изменения подтянулись при get
            // запросе
            this.clearFiltersAndQuery();
            // portalAccessServersService.getAccessServers(this, { query: true });
            portalAccessServersService.getAccessServers(this, { query: true, useLocationFilter: true });
          }
        }
      }
    },
    setDefaultCoaPortIfNotEntered(accessServer) {
      // проверяем введен ли CoA порт, если нет, то устанвливаем значение по умолчанию,
      // так как бэк ждет int, и выдает ошибки если прилетело пустое поле
      const defaultCoAPort = this.newAccessServer.coa_port;
      if (accessServer.coa_port === '') {
        accessServer.coa_port = defaultCoAPort;
      }
    },
    deleteAccessServer() {
      portalAccessServersService.deleteAccessServer(this, this.updatingAccessServer);
    },
    handleDeleteDialogClose() {
      this.isDeleteOpen = false;
    },
    openDeleteWindow() {
      this.isDeleteOpen = true;
    },
    closeDeleteWindow() {
      this.isDeleteOpen = false;
    },
    getWithQuery() {
      this.clearQuery();
      // portalAccessServersService.getAccessServers(this, { query: true });
      portalAccessServersService.getAccessServers(this, { query: true, useLocationFilter: true });
    },
    clearQuery() {
      this.offset = 0;
      this.filters.offset = 0;
      this.canLoadMore = true;
      this.accessServersListFromAPI = [];
    },
    clearFiltersAndQuery() {
      this.filters = {
        limit: 30,
        offset: 0,
        sort_by: 'name',
        sort_order: 1 /* 1 for ascending, -1 for descending */,
        search: ''
      };
      this.clearQuery();
    },
    scrollAndSetFocusToElementById(elementId) {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ block: 'center', behavior: 'smooth' });
        element.focus();
      }
    },
    scrollAndFocusToFirstFieldWithError() {
      // ищем среди ошибок первую, вытаскиваем оттуда id поля ввода и скролим туда
      if (this.errors.any()) {
        if (
          Object.prototype.hasOwnProperty.call(this.errors, 'items') &&
          Array.isArray(this.errors.items) &&
          this.errors.items.length > 0
        ) {
          const firstError = this.errors.items[0];
          if (Object.prototype.hasOwnProperty.call(firstError, 'field') && firstError.field !== '') {
            const firstErrorFieldId = firstError.field;
            this.scrollAndSetFocusToElementById(firstErrorFieldId);
          }
        }
      }
    }
  }
};
</script>

<style scoped>
.max-width-in-portal-access-servers-table {
  max-width: 140px;
  overflow: hidden;
  white-space: nowrap !important;
  text-overflow: ellipsis;
}

.validatorErrors {
  color: red;
}
</style>
<style>
.disable-turned-on-switcher-in-access-servers .switch-primary > .switch-input:checked ~ .switch-label {
  background-color: #94a0b2 !important;
}
</style>
